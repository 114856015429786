<template>
  <div class="detail-wrapper" v-if="shopDetail">
    <div class="detail-banner">
      <img class="_pic" :src="shopDetail.detail_back_img" alt="">
      <img class="_ding" src="https://pic.eaworks.cn/chenghua/icon_ding.png" alt="">
      <img class="_back" @click="$router.go(-1)" src="https://pic.eaworks.cn/chenghua/btn_back.png" alt="">
      <img class="_home" @click="$router.push('/home')" src="https://pic.eaworks.cn/chenghua/btn_home.png"
           alt="">
      <img class="_mobile_btn" @click="mobile_btn" src="https://pic.eaworks.cn/chenghua/mobile_btn.png" alt="">
    </div>
    <div class="detail-shop">
      <div class="_name">{{ shopDetail.merchant_name }}</div>
      <div class="_address">{{ shopDetail.merchant_address }}</div>
      <div class="_btns">
        <van-row>
          <van-col span="12"><span @click="goToAmap"></span></van-col>
          <van-col span="12"><span class="_like" @click="addLikeCnt">{{ shopDetail.like_cnt }}</span></van-col>
        </van-row>
      </div>
    </div>
    <div class="detail-content">
      <div class="_pic">
        <img :src="shopDetail.detail_img">
      </div>
    </div>
    <!-- 通用弹窗 -->
    <div class="dialog-wrapper" v-if="showDialog">
      <div class="dialog-content" v-if="dialogType === 1">
        <div class="img-wrapper">
          <img src="https://pic.eaworks.cn/chenghua/share_tips.png">
        </div>
      </div>
      <div class="dialog-closed">
        <span @click="cancelWrapperDialog"><img
          src="https://pic.eaworks.cn/chenghua/icon_closed.png"></span>
      </div>
      <div class="dialog-bg"></div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import page_wx from "../../mixin/page_wx";

export default {
  mixins: [page_wx],
  components: {},
  data() {
    return {
      shopDetail: null,
      showDialog: false,
      dialogType: 0,
      currentId: "",
      addFlag:false
    };
  },

  created() {
    this.currentId = this.$route.query.id
    // this.checkWeiXinCodeByDetail( this.currentId,() => {
    //   if ( this.currentId) {
    this.ajaxGetDetailData(this.currentId);
    this.openDialog(1)
    // }
    // });
  },

  mounted() {

  },

  methods: {
    openDialog(type = 1) {
      if (localStorage.getItem("open_detail_tips") !== "1") {
        this.showWrapperDialog(type);
        localStorage.setItem('open_detail_tips', '1')
      }
    },

    showWrapperDialog(type) {
      this.showDialog = true;
      this.dialogType = type;
    },
    mobile_btn() {
      if (this.shopDetail.mobile_phone !== '') {
        window.location.href = "tel:" + this.shopDetail.mobile_phone;
      }
    },
    cancelWrapperDialog() {
      this.showDialog = false;
      this.dialogType = 0;
    },
    async ajaxGetDetailData(id) {
      // 获取指定分商户详情
      let {data} = await this.$ajax.gateway("/api/api_get_merchant_detail", {
        "METHOD": "get",
        id
      });
      this.shopDetail = data;
      this.getJsInfo()
    },
    goToAmap() {
      window.location.href = "http://uri.amap.com/navigation?&to=" + this.shopDetail.gps_address.split(',')[1] + "," + this.shopDetail.gps_address.split(',')[0] + "," + this.shopDetail.merchant_address + "&callnative=1";
    },
    shareMerchant() {
      // let url  = "http://uri.amap.com/navigation?&to="+item.lng+","+item.lat+","+item.address+"&callnative=1";
      // window.location.href=url;
    },
    async addLikeCnt() {

      // 商户点赞
      let res = await this.$ajax.gateway("/api/api_add_merchant_like_cnt", {
        ERROR_BACK: true,
        "METHOD": "get",
        "id": this.shopDetail.id
      });
      if (res.data === "") {
        this.shopDetail.like_cnt = this.shopDetail.like_cnt + 1;
      } else {
        this.$toast.fail(res.data)
      }
    },
    async getJsInfo() {
      let _this = this;
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/api_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: _this.shopDetail.merchant_name, // 分享标题
          desc: '打卡「成华美食指南」品味成都地道美食', // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page#/shop/detail?id='+_this.currentId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://pic.eaworks.cn/chenghua/1.png', // 分享图标
          success: function (res) {
            // 设置成功
          }
        });
        wx.updateTimelineShareData({
          title: _this.shopDetail.merchant_name, // 分享标题
          desc: '打卡「成华美食指南」品味成都地道美食', // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page#/shop/detail?id='+_this.currentId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://pic.eaworks.cn/chenghua/1.png', // 分享图标
          success: function (res) {
            // 用户点击了分享后执行的回调函数
          }
        });
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
  }
};
</script>

<style lang="less" scoped>
.detail-banner {
  position: relative;

  img._pic {
    display: block;
    width: 100%;
  }

  img._ding {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  img._back {
    width: 1.8rem;
    position: fixed;
    top: 0.5rem;
    left: 0;
    z-index: 10;
  }

  img._home {
    width: 1.8rem;
    position: fixed;
    top: 1.5rem;
    left: 0;
    z-index: 10;
  }

  img._mobile_btn {
    width: 1.8rem;
    position: fixed;
    top: 2.5rem;
    left: 0;
    z-index: 10;
  }
}

.detail-shop {
  position: relative;
  margin-top: -3.7rem;
  display: block;
  width: 100%;
  height: 5.5rem;
  background: url("https://pic.eaworks.cn/chenghua/detail_bg.png") no-repeat top center;
  background-size: 100% 100%;

  ._name {
    font-size: 0.4rem;
    color: #9cb06f;
    padding: 2.5rem 1rem 0;
  }

  ._address {
    font-size: 0.3rem;
    color: #9cb06f;
    padding: 0.15rem 1rem 0.2rem;
    line-height: 0.4rem;
    height: 0.4rem;
  }

  ._btns {
    padding: 0.4rem 0.8rem 0.2rem;

    span {
      display: block;
      height: 1rem;

      &._like {
        padding-top: 0.3rem;
        padding-left: 1.7rem;
        font-size: 0.42rem;
        color:#9cb06f;
      }
    }
  }
}

.detail-content {
  padding: 0 0.6rem 0.6rem;

  ._pic {
    margin-top: -0.3rem;
    padding: 0.4rem 0;
    background: #80a44b;
    img {
      display: block;
      width: 100%;
    }
  }
}

.dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);

  .dialog-content {
    .img-wrapper {
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .dialog-closed {
    img {
      display: block;
      width: 1rem;
      margin: 0 auto;
    }
  }
}
</style>
